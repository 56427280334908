import React from 'react'
import { AnchorWithArrow } from '@/components/common/anchor'
import { css } from '@emotion/react'
import color from '@/constants/color'
import { mq } from '@/components/media/media'
import { FooterNavListType } from '@/typedir/types'

type Props = {
  navList: FooterNavListType[]
}

export const FooterNavList: React.FC<Props> = ({ navList }) => (
  <ul css={cssNavList}>
    {navList.map((navItem, index) => (
      <li key={index} css={cssNavItem}>
        <AnchorWithArrow
          href={navItem.url}
          css={cssNavLink}
          arrowFillColor={[color.gray6]}
        >
          {navItem.label}
        </AnchorWithArrow>
      </li>
    ))}
  </ul>
)

const cssNavList = css`
  display: flex;
  flex-wrap: wrap;
  max-width: 375px;
  margin: 16px auto 0;
  ${mq.pc} {
    flex-wrap: nowrap;
    align-items: center;
    max-width: none;
    margin: 0;
  }
`

const cssNavItem = css`
  width: 50%;
  padding: 8px 16px;
  margin: 8px 0;
  ${mq.pc} {
    width: auto;
    margin: 0;
    transform: translate(16px);
  }
`

export const cssNavLink = css`
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1.2px;
`
