import { FooterBottomType } from '@/typedir/types'
import { FooterNavListType } from '@/typedir/types'

export const footerBottomList: FooterBottomType[] = [
  {
    url: 'https://relic.co.jp/security/',
    label: '情報セキュリティ基本方針'
  },
  {
    url: '/policy',
    label: 'プライバシーポリシー'
  }
]

export const navList: FooterNavListType[] = [
  {
    url: '/',
    label: 'トップ'
  },
  {
    url: '/news',
    label: 'お知らせ'
  },
  {
    url: '/company',
    label: '会社情報'
  },
  {
    url: 'https://relic.co.jp/recruit/',
    label: '採用情報'
  },
  {
    url: 'https://relic.co.jp/contact/',
    label: 'お問い合わせ'
  }
]
