import React from 'react'
import { Helmet } from 'react-helmet'

import '@/styles/style.scss'
import favicon from '@/images/favicon.ico'
import appleIcon from '@/images/favicon_180.png'

export const Head = (props: { title: string; url: string }): JSX.Element => {
  const { title, url } = props

  return (
    <Helmet>
      <html lang="ja" />
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width,initial-scale=1" />
      <title>{title}</title>
      <meta
        name="description"
        content="CO-INNOVATION COMPANY 挑戦者と共創するインフラとなり1,000の事業と事業家を創出します。株式会社Relicホールディングスでは、日本企業の新規事業開発やイノベーション創出を支援するための事業やサービス・ソリューションを様々なアプローチで展開しています。"
      />
      <meta property="og:title" content={title} />
      <meta property="og:url" content={`https://relic-holdings.co.jp/${url}`} />
      <meta
        property="og:image"
        content="https://relic-holdings.co.jp/RelicHOLDINGS_OGP.jpg"
      />
      <meta
        property="og:description"
        content="CO-INNOVATION COMPANY 挑戦者と共創するインフラとなり1,000の事業と事業家を創出します。株式会社Relicホールディングスでは、日本企業の新規事業開発やイノベーション創出を支援するための事業やサービス・ソリューションを様々なアプローチで展開しています。"
      />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content="ja_JP" />
      <meta property="og:site_name" content="株式会社Relicホールディングス" />
      <meta name="twitter:card" content="summary_large_image" />
      <link rel="icon" href={favicon} />
      <link
        rel="apple-touch-icon-precomposed"
        href={appleIcon}
        sizes="180x180"
      />
    </Helmet>
  )
}
