import React from 'react'
import { Link } from 'gatsby'
import { css } from '@emotion/react'
import logo_relic_holdings from '@/images/logo_relic_holdings.svg'

type Props = {
  imgSrc?: string
  alt?: string
  label?: string
}

export const LogoAndLabel: React.FC<Props> = ({
  imgSrc = logo_relic_holdings,
  alt = 'ReLic HOLDINGS',
  label = '株式会社Relicホールディングス'
}) => (
  <Link css={cssAnchor} to="/" aria-label="トップページへ">
    <div css={cssLogoWrapper}>
      <img src={imgSrc} alt={alt} />
    </div>
    <span css={cssLabelWrapper}>{label}</span>
  </Link>
)

const cssAnchor = css`
  display: flex;
  align-items: center;
  justify-content: center;
`

const cssLogoWrapper = css`
  width: 54.6px;
`

const cssLabelWrapper = css`
  margin-left: 16px;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1.2px;
`
