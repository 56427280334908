import { HeaderNavListType } from '@/typedir/types'

export const headerNavList: HeaderNavListType[] = [
  { url: '/', label: 'トップ', labelEnglish: 'TOP' },
  { url: '/news', label: 'お知らせ', labelEnglish: 'NEWS' },
  { url: '/company', label: '会社情報', labelEnglish: 'COMPANY' },
  {
    url: 'https://relic.co.jp/recruit/',
    label: '採用情報',
    labelEnglish: 'RECRUIT'
  },
  {
    url: 'https://relic.co.jp/contact/',
    label: 'お問い合わせ',
    isContact: true
  }
]
