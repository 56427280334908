import React from 'react'
import { css } from '@emotion/react'
import color from '@/constants/color'
import { mq } from '@/components/media/media'
import { LogoAndLabel } from '@/components/common/logo-and-label'
import { FooterNavList } from '@/components/section/footer/footer-nav-list'
import { FooterNavListType } from '@/typedir/types'

type Props = {
  navList: FooterNavListType[]
}

export const FooterNav: React.FC<Props> = ({ navList }) => (
  <div css={cssFooterLayout}>
    <LogoAndLabel />
    <FooterNavList navList={navList} />
  </div>
)

const cssFooterLayout = css`
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: ${color.white1};
  ${mq.pc} {
    display: flex;
    justify-content: space-between;
    max-width: 1080px;
    padding: 40px 16px;
    margin: 0 auto;
  }
`
